import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const titleSlider1 = process.env.REACT_APP_TITLE_SLIDER1;
const subtitleSlider1 = process.env.REACT_APP_SUBTITLE_SLIDER1;
const titleSlider2 = process.env.REACT_APP_TITLE_SLIDER2;
const subtitleSlider2 = process.env.REACT_APP_SUBTITLE_SLIDER2;
const titleSlider3 = process.env.REACT_APP_TITLE_SLIDER3;
const subtitleSlider3 = process.env.REACT_APP_SUBTITLE_SLIDER3;
const titleSlider4 = process.env.REACT_APP_TITLE_SLIDER4;
const subtitleSlider4 = process.env.REACT_APP_SUBTITLE_SLIDER4;
const titleSlider5 = process.env.REACT_APP_TITLE_SLIDER5;
const subtitleSlider5 = process.env.REACT_APP_SUBTITLE_SLIDER5;

export const HomepageSlider = () => {
  useEffect(() => {
    document.title = `${restaurantName}: ${tagline}`;
  }, []);

  return (
    <div>
      {enableKiosk === "1" ? (
        <a href="/menu">
          <Carousel fade style={{ marginTop: "0px" }} controls={false}>
            {["A", "B", "C", "D", "E"].map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  src={`${siteMedSrc}slider_img_${item}.webp`}
                  alt={`Slide ${index + 1}`}
                  width="800"
                  height="400"
                  loading="lazy"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </a>
      ) : (
        <Carousel fade>
          {[
            {
              img: "header_img_1",
              title: titleSlider1,
              subtitle: subtitleSlider1,
            },
            {
              img: "header_img_2",
              title: titleSlider2,
              subtitle: subtitleSlider2,
            },
            {
              img: "header_img_3",
              title: titleSlider3,
              subtitle: subtitleSlider3,
            },
            {
              img: "header_img_4",
              title: titleSlider4,
              subtitle: subtitleSlider4,
            },
            {
              img: "header_img_5",
              title: titleSlider5,
              subtitle: subtitleSlider5,
            },
          ].map((slide, index) => (
            <Carousel.Item key={index}>
              <img
                src={`${siteMedSrc}${slide.img}.webp`}
                alt={slide.subtitle}
                width="800"
                height="400"
                title={slide.title}
                loading="lazy"
              />
              <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
                <div className="slider-caption">
                  <h1 style={{ lineHeight: 1.1, color: textColor }}>
                    {slide.title}
                  </h1>
                  <p>{slide.subtitle}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};
