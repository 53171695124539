import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImgListCard() {
  return (
    <ImageList
      sx={{ width: 500, height: 450 }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.alt}
            loading="lazy"
            title={item.title}
            width="100%"
            height="100%"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: `${siteMedSrc}A.webp`,
    title: `${restaurantName} Image-A`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-A`,
  },
  {
    img: `${siteMedSrc}B.webp`,
    title: `${restaurantName} Image-B`,
    alt: `${restaurantName} Image-B`,
  },
  {
    img: `${siteMedSrc}C.webp`,
    title: `${restaurantName} Image-C`,
    alt: `${restaurantName} Image-C`,
  },
  {
    img: `${siteMedSrc}D.webp`,
    title: `${restaurantName} Image-D`,
    cols: 2,
    alt: `${restaurantName} Image-D`,
  },
  {
    img: `${siteMedSrc}E.webp`,
    title: `${restaurantName} Image-E`,
    cols: 2,
    alt: `${restaurantName} Image-E`,
  },
  {
    img: `${siteMedSrc}F.webp`,
    title: `${restaurantName} Image-F`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-F`,
  },
  {
    img: `${siteMedSrc}G.webp`,
    title: `${restaurantName} Image-G`,
    alt: `${restaurantName} Image-G`,
  },
  {
    img: `${siteMedSrc}H.webp`,
    title: `${restaurantName} Image-H`,
    alt: `${restaurantName} Image-H`,
  },
  {
    img: `${siteMedSrc}I.webp`,
    title: `${restaurantName} Image-I`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-I`,
  },
  {
    img: `${siteMedSrc}J.webp`,
    title: `${restaurantName} Image-J`,
    alt: `${restaurantName} Image-J`,
  },
  {
    img: `${siteMedSrc}K.webp`,
    title: `${restaurantName} Image-K`,
    alt: `${restaurantName} Image-K`,
  },
  {
    img: `${siteMedSrc}L.webp`,
    title: `${restaurantName} Image-L`,
    cols: 2,
    alt: `${restaurantName} Image-L`,
  },
  {
    img: `${siteMedSrc}M.webp`,
    title: `${restaurantName} Image-M`,
    cols: 2,
    alt: `${restaurantName} Image-M`,
  },
  {
    img: `${siteMedSrc}N.webp`,
    title: `${restaurantName} Image-N`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-N`,
  },
  {
    img: `${siteMedSrc}O.webp`,
    title: `${restaurantName} Image-O`,
    alt: `${restaurantName} Image-O`,
  },
  {
    img: `${siteMedSrc}P.webp`,
    title: `${restaurantName} Image-P`,
    alt: `${restaurantName} Image-P`,
  },
  {
    img: `${siteMedSrc}Q.webp`,
    title: `${restaurantName} Image-Q`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-Q`,
  },
  {
    img: `${siteMedSrc}R.webp`,
    title: `${restaurantName} Image-R`,
    alt: `${restaurantName} Image-R`,
  },
  {
    img: `${siteMedSrc}S.webp`,
    title: `${restaurantName} Image-S`,
    alt: `${restaurantName} Image-S`,
  },
  {
    img: `${siteMedSrc}T.webp`,
    title: `${restaurantName} Image-T`,
    cols: 2,
    alt: `${restaurantName} Image-T`,
  },
];
