import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { FaHeart } from "react-icons/fa";
import { Container, Button, Col } from "react-bootstrap";
import { SocialConnect } from "./pages/homepage/SocialConnect";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import packageJson from "../../../../package.json";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const googleMaps = process.env.REACT_APP_GOO_GL_MAPS;
const phoneNumber = process.env.REACT_APP_CONTACT_US_PHONE;
const restaurantAddress = process.env.REACT_APP_RESTAURANT_ADDRESS;
const emailAddress = process.env.REACT_APP_CONTACT_US_EMAIL;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const promoText = process.env.REACT_APP_FOOTER_PROMOTEXT;
const serviceOptions = process.env.REACT_APP_SERVICE_OPTIONS;
const businessHoursLine1 = process.env.REACT_APP_BUSINESSHOURS_LINE1;
const businessHoursLine2 = process.env.REACT_APP_BUSINESSHOURS_LINE2;
const businessHoursLine3 = process.env.REACT_APP_BUSINESSHOURS_LINE3;
const businessHoursLine4 = process.env.REACT_APP_BUSINESSHOURS_LINE4;

export const Footer = () => {
  const history = useHistory();

  return (
    <div>
      <SocialConnect />
      <div
        className="footer-area padding-top-40"
        style={{
          backgroundImage: `url(${siteMedSrc}footer-bg.webp)`,
          color: textColor,
        }}
      >
        <div className="footer-top d-md-block">
          <div className="container">
            <div className="row align-items-center justify-content-between padding-bottom-25">
              <div className="col-lg-3 col-md-3">
                <div className="f-logo">
                  <a href="/">
                    {" "}
                    <img
                      width={logoWidth}
                      height="100%"
                      title={`${restaurantName} logo`}
                      className="App-logo"
                      src={siteMedSrc + "logo" + ".webp"}
                      alt={`${restaurantName} logo`}
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="f-title center">
                  <Typography
                    variant="h4"
                    gutterBottom
                    component="div"
                    style={{ lineHeight: 1.1 }}
                  >
                    <span style={{ color: textHighlight }}>{promoText}</span>{" "}
                    <br />
                    Visit us today!
                  </Typography>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <Button
                  className="btn-red"
                  href="#"
                  onClick={() => {
                    history.push("/menu");
                  }}
                  style={{
                    backgroundColor: buttonColor,
                    color: buttonText,
                    borderColor: buttonColor,
                    display: "none",
                  }}
                >
                  Order Now{" "}
                </Button>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="footer-bottom padding-top-22 padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography
                    className="padding-bottom-10"
                    variant="h5"
                    gutterBottom
                    component="div"
                    style={{
                      color: textHighlight,
                    }}
                  >
                    Address
                  </Typography>
                  <p>
                    {restaurantName} - Restaurant <br />
                    <a
                      aria-label="Google Map"
                      href={googleMaps}
                      style={{ color: textHighlight }}
                      target="_blank"
                    >
                      {restaurantAddress}
                    </a>
                    <br />
                    <a
                      aria-label={`Call Phone Number ${phoneNumber}`}
                      href={`tel:${phoneNumber}`}
                      style={{ color: textHighlight }}
                      target="_blank"
                    >
                      P: {phoneNumber}
                    </a>
                    <br />
                    <a
                      aria-label={`Email Address ${emailAddress}`}
                      href={`mailto:${emailAddress}`}
                      style={{ color: textHighlight }}
                      target="_blank"
                    >
                      E: {emailAddress}
                    </a>
                  </p>
                  <a
                    href={googleMaps}
                    className="btn-red"
                    style={{
                      backgroundColor: buttonColor,
                      color: buttonText,
                      borderColor: buttonColor,
                      display: "inline-block",
                      padding: "10px 20px",
                      border: "1px solid",
                      borderRadius: "5px",
                      textDecoration: "none",
                      textAlign: "center",
                    }}
                    target="_blank"
                  >
                    View Location
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography
                    className="padding-bottom-10"
                    variant="h5"
                    gutterBottom
                    component="div"
                    style={{
                      color: textHighlight,
                    }}
                  >
                    Service Options
                  </Typography>
                  <p>{serviceOptions}</p>
                  <a
                    href="#"
                    onClick={() => {
                      history.push("/menu");
                    }}
                    className="btn-red"
                    style={{
                      backgroundColor: buttonColor,
                      color: buttonText,
                      borderColor: buttonColor,
                      display: "inline-block",
                      padding: "10px 20px",
                      border: "1px solid",
                      borderRadius: "5px",
                      textDecoration: "none",
                      textAlign: "center",
                    }}
                  >
                    Order Now
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <Typography
                    className="padding-bottom-10"
                    variant="h5"
                    gutterBottom
                    component="div"
                    style={{
                      color: textHighlight,
                    }}
                  >
                    Business Hours
                  </Typography>
                  <p>
                    <span>{businessHoursLine1}</span>
                    <br></br>
                    <span>{businessHoursLine2}</span>
                    <br></br>
                    <span>{businessHoursLine3}</span>
                    <br></br>
                    <span>{businessHoursLine4}</span>
                  </p>
                  <a
                    aria-label={`Call Phone Number ${phoneNumber}`}
                    href={`tel:${phoneNumber}`}
                    className="btn-red"
                    style={{
                      backgroundColor: buttonColor,
                      color: buttonText,
                      borderColor: buttonColor,
                      display: "inline-block",
                      padding: "10px 20px",
                      border: "1px solid",
                      borderRadius: "5px",
                      textDecoration: "none",
                      textAlign: "center",
                    }}
                    target="_blank"
                  >
                    Make a Call
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget heart">
                  <p>
                    &copy; {new Date().getFullYear()} {restaurantName} by
                    ServingIntel, made with{" "}
                    <span>
                      <FaHeart />{" "}
                    </span>{" "}
                    for a better web. Version {packageJson.version}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container className="footer">
          <p className="privacy">
            <a
              href="#"
              onClick={() => {
                history.push("/privacy-policy");
              }}
            >
              Privacy Policy
            </a>
          </p>
        </Container>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="myAwesomeCookieName2"
        buttonClasses="btn btn-red"
        style={{
          backgroundColor: buttonColor,
          color: buttonText,
          borderColor: buttonColor,
        }}
        buttonStyle={{ fontSize: "16px" }}
        expires={365}
      >
        <div className="center">
          <Col xs={3} className="padding-bottom-10 center">
            <img
              width="100%"
              height="100%"
              title="Accept all cookies Icon"
              src={siteMedSrc + "cookie-icon" + ".svg"}
              size="15"
              alt="Accept all cookies Icon"
              loading="lazy"
            />
          </Col>
          <p>
            <h3 className="center"> Your Privacy </h3>
          </p>
        </div>

        <Typography>
          Greetings! Our website uses cookies so we may better serve you. By
          clicking “Accept all cookies” and by continuing to browse our site you
          are agreeing to our{" "}
        </Typography>
        <Typography>
          <a
            href="#"
            onClick={() => {
              history.push("/terms-and-conditions");
            }}
          >
            Terms and Conditions
          </a>
        </Typography>
      </CookieConsent>
    </div>
  );
};
