import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

const googleMaps = process.env.REACT_APP_GOO_GL_MAPS;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const streetAddress = process.env.REACT_APP_STREET_ADDRESS;
const addressLocality = process.env.REACT_APP_ADDRESS_LOCALITY;
const addressRegion = process.env.REACT_APP_ADDRESS_REGION;
const zipCode = process.env.REACT_APP_ZIP_CODE;
const textColor = process.env.REACT_APP_TEXT_COLOR;

export default function DirectionCard() {
  return (
    <Card>
      <a
        aria-label="Google Map"
        href={googleMaps}
        className="none-link"
        target="_blank"
      >
        <CardActionArea
          className="color-scheme"
          style={{ backgroundColor: colorBackground }}
        >
          <CardMedia
            component="img"
            height="140px"
            image={siteMedSrc + "get-direction" + ".webp"}
            width="100%"
            title={`${restaurantName} Store Location`}
            alt={`${restaurantName} Store Location`}
            loading="lazy"
          />
          <CardContent style={{ color: textColor, height: "200px" }}>
            <Typography gutterBottom variant="h4" component="div">
              Store Location
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              {streetAddress}, {addressLocality}
              <br></br>
              {addressRegion}, {zipCode} US
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
}
