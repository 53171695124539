import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const phoneNumber = process.env.REACT_APP_CONTACT_US_PHONE;
const emailAddress = process.env.REACT_APP_CONTACT_US_EMAIL;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textColor = process.env.REACT_APP_TEXT_COLOR;

export default function PhoneCard() {
  return (
    <Card>
      <a
        aria-label={`Call Phone Number ${phoneNumber}`}
        href={`tel:${phoneNumber}`}
        className="none-link"
        target="_blank"
      >
        <CardActionArea
          className="color-scheme"
          style={{ backgroundColor: colorBackground }}
        >
          <CardMedia
            component="img"
            height="140px"
            image={siteMedSrc + "callusnow" + ".webp"}
            width="100%"
            title={`${restaurantName} Contact Number`}
            alt={`${restaurantName} Contact Number`}
            loading="lazy"
          />
          <CardContent style={{ color: textColor, height: "200px" }}>
            <Typography gutterBottom variant="h4" component="div">
              Contact Number
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              Phone: {phoneNumber}
              {/* <br></br>
            Email: {emailAddress} */}
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
}
